<template>
  <v-layout class="main-wrapper" column>
    <v-app-bar color="primary" elevate-on-scroll scroll-target app>
      <v-app-bar-nav-icon v-if="_routes.length" @click="drawer = !drawer" />

      <v-spacer />
    </v-app-bar>

    <v-navigation-drawer
      v-if="_routes.length"
      v-model="drawer"
      color="background1"
      :mini-variant="$vuetify.breakpoint.lgAndUp"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <div class="d-flex justify-center">
          <v-img
            :src="require('@/assets/lgbanking/logomark.png')"
            class="my-6"
            width="2rem"
            height="2rem"
            contain
          />
        </div>

        <v-list class="py-0" color="transparent" dense nav>
          <router-link
            v-for="(item, i) in _routes"
            v-slot="{ href, navigate, isActive }"
            :to="item.path"
            :key="i"
            custom
          >
            <v-tooltip color="black" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  :href="href"
                  @click="navigate"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon :color="isActive ? 'primary' : 'background3'">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>
              </template>

              <div>{{ item.label }}</div>
            </v-tooltip>
          </router-link>
        </v-list>

        <v-spacer />

        <v-list color="transparent" nav dense>
          <v-tooltip color="black" right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" @click="signOut()">
                <v-list-item-icon class="mr-2">
                  <v-icon> mdi-logout </v-icon>
                </v-list-item-icon>

                <v-list-item-title> Sair </v-list-item-title>
              </v-list-item>
            </template>

            <div>Sair</div>
          </v-tooltip>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-container class="view-container" fluid>
      <router-view class="card-router-view" />
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "Main",

  data() {
    return {
      drawer: true,
    };
  },

  computed: {
    _routes() {
      const routes = [
        {
          icon: "mdi-view-dashboard-outline",
          label: "Início",
          path: "/home",
          permission: this.$can("account-exchanges"),
        },
        {
          icon: "mdi-code-block-braces",
          label: "Algoritmos",
          path: "/algorithms",
          permission: this.$can("algorithms"),
        },
        /*  {
          icon: "mdi-swap-vertical",
          label: "Transações",
          path: "/transactions",
          permission: this.$can("trades"),
        }, */
        {
          icon: "mdi-xml",
          label: "Gerenciar Algoritmos",
          path: "/manage-algorithms",
          permission: this.$can("algorithms"),
        },
        {
          icon: "mdi-chart-box-outline",
          label: "Exchanges",
          path: "/exchanges",
          permission: this.$can("exchanges"),
        },
        {
          icon: "mdi-code-parentheses-box",
          label: "Pares",
          path: "/pairs",
          permission: this.$can("pairs"),
        },
        /* {
          icon: "mdi-currency-btc",
          label: "Criptomoedas",
          path: "/currencies",
          permission: this.$can("coins"),
        }, */
        {
          icon: "mdi-api",
          label: "Requisições",
          path: "/requests",
          permission: this.$can("requests"),
        },
        {
          icon: "mdi-clipboard-text-clock",
          label: "Logs",
          path: "/logs",
          permission: this.$can("logs"),
        },
      ];

      return routes.filter((el) => el.permission ?? true);
    },
  },

  methods: {
    signOut() {
      this.$root.$emit("sign-out");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
